<template>
  <div class="flex flex-column flex-grow-1 gap-4">
    <div class="flex flex-row justify-content-between">
      <NameHeader :independent="independent" :backward-button="false" starterTitle showAvatar/>
    </div>
    <div class="flex gap-2">
      <CalendarRange :model-value="selectedDates" @update:model-value="getData"/>
      <Button :label="$t('export')" size="small" icon="pi pi-external-link" @click="exportSynthesisSheet"/>
      <Button v-if="me.role.name === constants.ROLES.ADMIN" :label="$t('craExport')" size="small" icon="pi pi-download" @click="downloadCras"/>
    </div>
    <div v-if="getIsBrowserApp" class="flex flex-column flex-grow-1">
      <h3>{{ $t('syntheseGenerale') }}</h3>
      <div class="card">
        <general-synthesis :summaries="summaries" :loading="loading"/>
      </div>
      <h3>Factures</h3>
      <div class="card">
        <invoices-synthesis :invoices="invoicesIndependent" :loading="loading"/>
      </div>
      <h3>Salaires</h3>
      <div class="card">
        <salaries-synthesis :salaries="salariesIndependent" :loading="loading"/>
      </div>
      <h3>{{ $t('syntheseNDF') }}</h3>
      <div class="card">
        <n-d-f-synthesis :summaries="summaries" :loading="loading" :independent="independent"/>
      </div>
    </div>
  </div>
</template>

<script>
import GeneralSynthesis from "@/components/Dashboard/GeneralSynthesis.vue";
import NDFSynthesis from "@/components/Dashboard/NDFSynthesis.vue";
import isBrowserAppMixin from "@/mixins/isBrowserAppMixin";
import {mapState} from "vuex";
import NameHeader from "@/components/NameHeader.vue";
import {getEuroFormat} from "@/utils/Money";
import InvoicesSynthesis from "@/components/Dashboard/InvoicesSynthesis.vue";
import SalariesSynthesis from "@/components/Dashboard/SalariesSynthesis.vue";
import SheetGeneratorRefactor from "@/utils/SheetGeneratorRefactor";
import CalendarRange from "@/components/CalendarRange.vue";

export default {
  name: "Dashboard",
  mixins: [isBrowserAppMixin],
  props: {
    independent: {
      type: Object,
      required: true
    }
  },
  components: {CalendarRange, SalariesSynthesis, InvoicesSynthesis, NameHeader, GeneralSynthesis, NDFSynthesis },
  data () {
    return {
      selectedDates: null,
      loading: false,

      summaries: [],
      ndfsIndependent: [],
      salariesIndependent: [],
      invoicesIndependent: [],

      currentYear: (new Date()).getFullYear()
    }
  },
  computed: {
    ...mapState({
      constants: state => state.constants,
      me: state => state.auth.me
    })
  },
  created () {
    // range start is the starting date of the indep and the range end is the current month
    this.selectedDates = [null, null]
    const rangeStartDate = new Date(this.independent?.profil?.workContract?.startingDate)
    this.selectedDates[0] = rangeStartDate ? new Date(rangeStartDate.getFullYear(), rangeStartDate.getMonth(), 1) : null
    this.selectedDates[1] = new Date()

    this.getData(this.selectedDates)
  },
  methods: {
    getEuroFormat,
    formatDate(value) {
      if (!value) return
      if (typeof value === 'string') {
        return value
      }
      return value.toLocaleDateString('fr-FR', {
        month: '2-digit',
        year: 'numeric',
      });
    },
    async getData (range) {
      this.loading = true
      this.summaries = []
      if (this.getIsBrowserApp) {
        const summary = await this.$store.dispatch('independent/getSummaryByIndependentPerMonth', { id: this.independent.id, range })
        this.invoicesIndependent = summary.invoices
        this.salariesIndependent = summary.salaries

        Object.keys(summary.synthesis).forEach(year => {
          Object.keys(summary.synthesis[year]).forEach(month => {
            this.summaries.push({
              year: parseInt(year),
              month: parseInt(month),
              ...summary.synthesis[year][month]
            })
          })
        })
      }
      this.loading = false
    },
    exportSynthesisSheet () {
      SheetGeneratorRefactor({ ids: [], range: this.selectedDates }, `/independent/export-sheet-synthesis/${this.independent.id}`, this)
          .then(() => {
            this.exportSheetLoading = false
          })
          .catch(() => {
            this.exportSheetLoading = false
          })
    },
    async downloadCras () {
      this.downloadPdfLoading = true
      // get directly the filtered and sorted data in the dataTable
      let res = await this.$store.dispatch('declarationFacturation/downloadCras', { independentId: this.independent.id })
      console.log(res)
      const blob = new Blob([new Uint8Array(res.data.data)], { type: 'application/zip' })
      const link = document.createElement('a')
      link.download = 'Cras.zip'
      const url = URL.createObjectURL(blob)
      link.href = url
      link.click()
      URL.revokeObjectURL(url)
      this.downloadPdfLoading = false
    }
  }
}
</script>

<style scoped>

</style>
